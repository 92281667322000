<template>
  <Card>
    <template #title>
      <h3 class="p-m-0">
        <strong>{{ _t('menu.holidays') }}</strong>
      </h3>
    </template>
    <template #content>
      <div class="p-grid">
        <div class="p-col p-mb-2">
          <MultiSelectableBreadcrumb
              ref="breadcrumbData"
              :tree-params="treeParams"
              single-selection
              @change="setOrgId($event)"
          />
        </div>
      </div>

      <custom-full-calendar
          v-model:date="date"
          :events="events"
          :holidays="getHolidays"
          :type="calendarType"
          :view="calendarView"
          :viewTypes="calendarViewTypes"
          @click-day="clickDay"
          @click-event="clickEvent"
      ></custom-full-calendar>
    </template>
  </Card>


  <Dialog v-model:visible="newEventDialog" :modal="true" style="width: 500px">
    <template #header>
      <h3>{{ _t('label_Add_new_event') }} {{ getHoliday && getHoliday.id ? `#${getHoliday.id}` : '' }}</h3>
    </template>

    <span class="p-float-label p-mt-5">
      <InputText id="eventTitle" v-model="getHoliday.title" style="width: 100%" type="text"/>
      <label for="eventTitle">{{ _t('specify_title') }} *</label>
    </span>
    <small v-if="!getHoliday.title || getHoliday.title.length === 0" class="p-error">{{ _t('specify_title') }}</small>
    <div class="p-d-flex">
      <span class="p-float-label p-d-inline-flex p-mt-5">
        <Calendar
            id="eventDateStart"
            v-model="getHoliday.start"
            :manualInput="false"
            dateFormat="dd.mm.yy"
            style="width: 100%"
        />
        <label for="eventDateStart">{{ getHoliday.singleDay ? _t('label_Date') + ' *' : 'Date from' }}</label>
      </span>
      <span class="p-float-label p-d-inline-flex p-mt-5 p-ml-2">
        <Calendar
            id="eventDateEnd"
            v-model="getHoliday.end"
            :class="{ 'p-disabled': getHoliday.singleDay }"
            :manualInput="false"
            :minDate="getHoliday.start"
            dateFormat="dd.mm.yy"
            style="width: 100%"
        />
        <label for="eventDateEnd">{{ getHoliday.singleDay ? '' : 'Date to' }}</label>
      </span>
      <div class="p-field-checkbox p-d-inline-flex p-mt-5 p-ml-2 p-mb-0" style="min-width: 75px">
        <Checkbox id="eventSingleDay" v-model="getHoliday.singleDay" :binary="true" :disabled="true"/>
        <label for="eventSingleDay">{{ _t('label_Single_day') }}</label>
      </div>
    </div>
    <div class="p-mt-5 p-d-flex">
      <div class="p-float-label p-d-inline-flex">
        <Calendar id="eventTimeStart" v-model="getHoliday.start" :class="{ 'p-disabled': getHoliday.allDay }"
                  :disabled="getHoliday.allDay"
                  :showTime="true" :timeOnly="true"/>
        <label for="eventTimeStart">{{ _t('label.Start_time') }}</label>
      </div>
      <div class="p-float-label p-d-inline-flex p-ml-2">
        <Calendar id="eventTimeEnd" v-model="getHoliday.end" :class="{ 'p-disabled': getHoliday.allDay }"
                  :disabled="getHoliday.allDay"
                  :showTime="true" :timeOnly="true"/>
        <label for="eventTimeEnd">{{ _t('label.End_time') }}</label>
      </div>
      <div class="p-field-checkbox p-d-inline-flex p-my-0 p-ml-2" style="min-width: 75px">
        <Checkbox id="eventAllDay" v-model="getHoliday.allDay" :binary="true"/>
        <label for="eventAllDay">{{ _t('label_all_day') }}</label>
      </div>
    </div>
    <small v-if="getHoliday.start > getHoliday.end" class="p-error">{{ _t('label_start_end_dates') }}</small>
    <div class="p-field-checkbox p-d-inline-flex p-mt-4 p-ml-0" style="width: 100%;">
      <Checkbox id="eventRepeatYearly" v-model="getHoliday.recurring" :binary="true"/>
      <label for="eventRepeatYearly">{{ _t('repeat_yearly') }}</label>
    </div>
    <div class="p-field-checkbox p-d-inline-flex p-mt-0 p-ml-0" style="width: 100%;">
      <Checkbox id="eventActive" v-model="getHoliday.active" :binary="true"/>
      <label for="eventActive">{{ _t('label_active') }}</label>
    </div>


    <template #footer>
      <Button v-if="getIsEditable" :label="_t('label_Remove')" class="p-button-text p-button-danger" icon="pi pi-trash"
              style="float: left" @click="removeHoliday()"/>
      <Button :label="_t('Cancel')" class="p-button-info" icon="pi pi-times"
              @click="newEventDialog = false; setHoliday(null)"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" icon="pi pi-check"
              @click="getHoliday.start && getHoliday.end && getHoliday.title ? createUpdateHoliday() : null"/>
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment-timezone';
import CustomFullCalendar from '@/components/CustomFullCalendar';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import MultiSelectableBreadcrumb from '@/components/ixarma/MultiSelectableBreadcrumb';

export default {
  components: {
    'custom-full-calendar': CustomFullCalendar,
    'MultiSelectableBreadcrumb': MultiSelectableBreadcrumb,
  },
  data: () => ({
    date: moment(),
    calendarView: ViewEnum.DAYGRID,
    calendarType: ViewTypeEnum.MONTH,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => [ViewTypeEnum.MONTH, ViewTypeEnum.YEAR].includes(i)).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    orgId: 1,
    treeParams: null,
    newEventDialog: false,
  }),
  created() {
    this.callGetHolidays({orgId: this.orgId, year: moment().year()});
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      'callCreateHoliday': 'holidays/callCreateHoliday',
      'callUpdateHoliday': 'holidays/callUpdateHoliday',
      'callGetHolidays': 'holidays/callGetHolidays',
      'callDeleteHoliday': 'holidays/callDeleteHoliday',
    }),
    ...mapMutations({
      'setHoliday': 'holidays/setHoliday',
      'createEmptyHoliday': 'holidays/createEmptyHoliday',
      'selectHoliday': 'holidays/selectHoliday',
    }),
    setOrgId(orgId) {
      if (orgId) {
        this.orgId = orgId;
        this.callGetHolidays({orgId, year: moment(this.date).year()});
      }
    },
    clickDay(event) {
      const date = event.target.getAttribute('data-date');
      this.createEmptyHoliday(date);
      this.newEventDialog = true;
    },
    clickEvent(event) {
      const eventId = event.target.getAttribute('data-id');
      this.selectHoliday(eventId);
      this.newEventDialog = true;
    },
    createUpdateHoliday() {
      if (this.getHoliday.start <= this.getHoliday.end) {
        if (this.getHoliday && this.getHoliday.id) {
          this.callUpdateHoliday(this.orgId);
        } else {
          this.callCreateHoliday(this.orgId);
        }
        this.newEventDialog = false;
      }
    },
    removeHoliday() {
      if (this.getHoliday) {
        this.newEventDialog = false;
        this.callDeleteHoliday(this.orgId);
      }
    }
  },
  computed: {
    ...mapGetters({
      'getIsEditable': 'holidays/getIsEditable',
      'getHolidays': 'holidays/getHolidays',
      'getHoliday': 'holidays/getHoliday',
    }),
    events: function () {
      if (!this.getHolidays) return [];
      return JSON.parse(JSON.stringify(this.getHolidays)).map(h => ({
        id: h.id,
        title: h.title,
        recurring: h.recurring,
        times: [
          {
            start: h.recurring ? moment(h.start).year(this.date.year()) : moment(h.start),
            end: h.recurring ? moment(h.end).year(this.date.year()) : moment(h.end),
          },
        ],
      }));
    }
  },
  watch: {
    'event.dateStart': function () {
      if (this.event.singleDay) {
        this.event.dateEnd = this.event.dateStart;
      } else {
        if (moment(this.event.dateStart).isSameOrAfter(this.event.dateEnd)) {
          this.event.dateEnd = this.event.dateStart;
        }
      }
    },
    'event.allDay': function () {
      if (this.event.allDay) {
        this.event.start = '00:00';
        this.event.end = '23:59';
      }
    },
    date: function () {
      this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    }
  },
}
</script>
